import gql from 'graphql-tag';

const AdminFragment = gql`
  fragment AdminFragment on Admin {
    id
    __typename
    name
    subID
    agentID
    email
    networkID
    role
    publisherID
    vendorID
    flags {
      allowLeadUploads
      allowLeadResults
    }
  }
`;

export default AdminFragment;
