import gql from 'graphql-tag';

export default gql`
  subscription reportNotification($vendorID: String, $publisherID: String) {
    notification: reportNotification(
      vendorID: $vendorID
      publisherID: $publisherID
    ) {
      data
    }
  }
`;
