/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect, useMemo } from 'react';
import {
  Table,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  DropdownItem,
  DropdownMenu,
  UncontrolledButtonDropdown,
  DropdownToggle,
  Row,
  Col,
  InputGroup,
  Button,
} from 'reactstrap';
import qs from 'querystring';
import { Storage } from 'aws-amplify';
import Pagination, {
  PaginationContent,
  usePagination,
} from '@availity/pagination';
import {
  FaCheckCircle,
  FaSort,
  FaSortDown,
  FaSortUp,
  FaLock,
  FaPhone,
  FaCircle,
  FaUserFriends,
  FaCalendarMinus,
  FaSync,
} from 'react-icons/fa';

import { Formik } from 'formik';
import { Input } from '@availity/form';
import { SelectField } from '@availity/select';
import { DateRangeField } from '@availity/date';
import moment from 'moment';
import * as yup from 'yup';
import { dateRange } from '@availity/yup';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { useHistory, useLocation } from 'react-router-dom';

import {
  leadPaginationQuery,
  leadAggregationQuery,
  publisherManyQuery,
  reportManyQuery,
} from '@/graphql/queries';
import {
  LeadsShowExportModal,
  LeadsUploadModal,
  TableOptionsModal,
} from '@/modals';
import { publisherColumns, vendorColumns } from '@/utils/leadTableHelpers';
import { formatVendorCampaign, leadResultOptions, Vars } from '@/utils';
import { SearchControls, SearchSummary } from '@/components';
import omit from 'lodash.omit';
import { useAdmin, useNotifications } from '@/hooks';

interface ColumnConfig {
  dataField: string;
  displayName: string;
  formatter?: (...value: any) => any;
}

interface TableRowProps {
  [key: string]: any;
  columns: ColumnConfig[];
  sortKey: string;
  descending: boolean;
}

const getIcon = (icon: string, color: string) => {
  switch (icon) {
    case 'phone':
      return <FaPhone color={color} />;
    case 'lock':
      return <FaLock color={color} />;
    case 'people':
      return <FaUserFriends color={color} />;
    default:
      return <FaCircle color={color} />;
  }
};
const getColor = (type: string): string => {
  switch (type) {
    case 'reject':
      return 'red';
    case 'block':
      return 'yellow';
    case 'pass':
      return 'green';
    default:
      return 'green';
  }
};

const TableRow: React.SFC<TableRowProps> = ({
  columns,
  filters,
  sortKey,
  descending,
  ...rest
}) => (
  <tr>
    {columns.map((column, key) => {
      const { dataField, formatter } = column;
      if (dataField === 'result') {
        const { icon = '', result = '' } = rest[dataField] || {};
        return (
          <td key={`${key}-${dataField}`}>
            {getIcon(icon, getColor(rest.status))}
          </td>
        );
      }

      if (dataField === 'date') {
        const value = rest[filters?.dateType];
        return (
          <td key={`${key}-date`}>
            {formatter !== undefined ? formatter(value) : value}
          </td>
        );
      }

      if (dataField.startsWith('custom')) {
        return (
          <td key={`${key}-${dataField}`}>
            <div className="d-flex justify-content-center">
              {' '}
              {formatter!(rest)}
            </div>
          </td>
        );
      }

      return (
        <td key={`${key}-${dataField}`}>
          {formatter !== undefined ? (
            <div className="d-flex justify-content-center">
              {' '}
              {formatter(rest[dataField])}
            </div>
          ) : (
            rest[dataField]
          )}
        </td>
      );
    })}
  </tr>
);

const NoItemsMessage: React.SFC<React.HTMLAttributes<HTMLSpanElement>> = ({
  children,
  ...rest
}) => {
  const { page, loading } = usePagination();

  if (loading && (!page || page.length === 0)) {
    return <span {...rest}>Loading Items...</span>;
  }

  return <span {...rest}>{!page || (!page.length && children)}</span>;
};

const Leads: React.SFC = () => {
  const [intitialPage, setInitialPage] = useState(1);
  const [refresh, setRefresh] = useState(false);
  const history = useHistory();
  const { create } = useNotifications();
  const location = useLocation();
  const initialSearch = useMemo(() => qs.parse(location.search.slice(1)), []);
  const [showExportModal, setShowExportModal] = useState(false);
  const [uploadModal, setUploadModal] = useState(false);
  const toggleUploadModal = () => setUploadModal((o) => !o);
  const [showTableOptionsModal, setTableOptionsModal] = useState(false);
  const [elasticQuery, setElasticQuery] = useState<any>({});
  let availableColumns: any;
  const { publisherID, vendorID, role, flags } = useAdmin();

  const { data = {}, loading } = useQuery(reportManyQuery, {
    skip: !publisherID && !vendorID,
    variables: {
      folderID: publisherID || vendorID,
      reportFolder: publisherID ? 'publisher' : 'vendor',
    },
  });

  if (role === 'publisher') {
    availableColumns = publisherColumns;
  } else {
    availableColumns = vendorColumns;
  }

  const exportItems = async ({ columns, ...values }: any): Promise<void> => {
    setShowExportModal(true);

    const filePath = `leads/exports/searchPayload-${moment().format()}.json`;

    const index = columns.findIndex(
      (s: string) => s === 'date' || s === 'created'
    );

    if (index !== -1) columns[index] = 'created';

    const customResultIndex = columns.findIndex((s) => s === 'custom-result');

    if (customResultIndex !== -1) {
      columns = columns.concat([
        'passQuality',
        'passDNC',
        'isDuplicate',
        'emailOnly',
      ]);
    }

    Storage.vault.put(
      filePath,
      JSON.stringify({
        ...elasticQuery,
        fields: columns.filter(
          (item: string) => item !== 'result' && item.startsWith('custom')
        ),
      }),
      {
        level: 'private',
        contentType: 'application/json',
        bucket: `report-filters-${Vars.env}`,
        region: 'us-east-1',
      }
    );
  };

  const { reportMany = [] } = data;

  if (loading) return null;

  const noGeneratingReports = reportMany.every(
    ({ status }: any) => status !== 'GENERATING'
  );

  return (
    <Formik<{
      size: number;
      searchText: string;
      sort: any;
      vendorName?: any;
      dateType: string;
      dateRange: any;
      columns: string[];
      region?: string;
      company?: string;
      layerID?: string;
      mpVendorCampaignName?: string;
      leadResult?: string;
      publisherID: string;
      page: number;
    }>
      initialValues={{
        page: intitialPage,
        size: localStorage.getItem('leads-table-page-size')
          ? // eslint-disable-next-line radix
            parseInt(localStorage.getItem('leads-table-page-size')!)
          : 25,
        searchText: initialSearch.q || '',
        sort: {
          created: 'DESC',
        },
        dateType: 'created',
        dateRange:
          initialSearch.fr && initialSearch.to
            ? {
                startDate: moment(initialSearch.fr, ['YYYY-MM-DD']),
                endDate: moment(initialSearch.to, ['YYYY-MM-DD']),
              }
            : {
                startDate: moment({ hour: 0 }).subtract(29, 'days'),
                endDate: moment({ hour: 0 }),
              },
        columns: availableColumns
          .filter(
            (ac) =>
              ac.default &&
              ac.dataField !== 'leadID' &&
              ac.dataField !== 'tierID'
          )
          .map((aC) => aC.dataField),
        vendorName: initialSearch.v,
        region: initialSearch.r,
        company: initialSearch.c,
        layerID: initialSearch.l,
        mpVendorCampaignName: initialSearch.m,
        leadResult: initialSearch.lr,
        publisherID,
      }}
      validationSchema={yup.object().shape({
        size: yup.number(),
        searchText: yup.string(),
        dateRange: dateRange({}),
        sort: yup.object(),
        dateType: yup.string(),
      })}
      onSubmit={() => {}}
    >
      {({ values, setFieldValue, errors }) => {
        const client = useApolloClient();

        setInitialPage(values.page);

        const created = {
          gte: values.dateRange.startDate,
          lte: values.dateRange.endDate,
        };

        const filter = {
          q: values.searchText,
          vendorName: values.vendorName || undefined,
          leadResult: values.leadResult,
          publisherID: publisherID || values.publisherID || undefined,
          region: values.region || undefined,
          company: values.company || undefined,
          layerID: values.layerID || undefined,
          mpVendorCampaignName: values.mpVendorCampaignName || undefined,
          [values.dateType]:
            created.gte !== undefined && created.lte !== undefined
              ? created
              : undefined,
        };

        const { data: leadAggData } = useQuery(leadAggregationQuery, {
          variables: {
            fields: [
              'publisherID.keyword',
              'region.keyword',
              'company.keyword',
              'layerID.keyword',
              'mpVendorCampaignName.keyword',
            ],
            filter: omit(filter, [
              'publisherID',
              'region',
              'company',
              'layerID',
              'mpVendorCampaignName',
            ]),
          },
        });

        const { data: publisherData } = useQuery(publisherManyQuery, {
          variables: {
            publisherIDs:
              leadAggData?.leadAggregation
                .find((f: any) => f.field === 'publisherID.keyword')
                .counts.map(({ key }: any) => key) || [],
          },
        });

        const { publisherMany: pubMany = [] } = publisherData || {};
        useEffect(() => {
          history.push(
            `/leads?${qs.stringify({
              q: values.searchText,
              lr: values.leadResult,
              v: values.vendorName,
              fr:
                typeof values.dateRange.startDate === 'string'
                  ? values.dateRange.startDate
                  : values.dateRange.startDate.format('YYYY-MM-DD'),
              to:
                typeof values.dateRange.endDate === 'string'
                  ? values.dateRange.endDate
                  : values.dateRange.endDate?.format('YYYY-MM-DD'),
              r: values.region,
              c: values.company,
              l: values.layerID,
              m: values.mpVendorCampaignName,
            })}`
          );
        }, [
          values.leadResult,
          values.vendorName,
          values.searchText,
          values.dateRange,
          values.dateType,
          values.region,
          values.company,
          values.layerID,
          values.mpVendorCampaignName,
        ]);

        useEffect(() => {
          localStorage.setItem('leads-table-page-size', values.size.toString());
        }, [values.size]);

        const fetchItems = async (
          currentPage: number,
          itemsPerPage: number
        ): Promise<any> => {
          const response = await client.query<any>({
            query: leadPaginationQuery,
            fetchPolicy: 'network-only',
            variables: {
              perPage: itemsPerPage,
              page: currentPage,
              filter,
              orderBy: values.sort,
            },
          });

          const currentPageData = response.data?.leadPagination.items;

          setElasticQuery({
            type: 'lead',
            folderID: publisherID || vendorID || undefined,
            reportFolder: publisherID ? 'publisher' : 'vendor',
            filter: {
              dateField: values.dateType,
              startDate: values.dateRange.startDate,
              endDate: values.dateRange.endDate,
              vendorID: vendorID || undefined,
              ...filter,
            },
          });

          return {
            totalCount: response.data.leadPagination.count,
            items: currentPageData,
          };
          // }
        };

        const getSortIcon = (dataField: string): React.ReactNode => {
          if (dataField === 'date') {
            dataField = values.dateType;
          }

          const { [dataField]: sort } = values.sort;

          if (sort === 'DESC') {
            return <FaSortDown />;
          }

          if (sort === 'ASC') {
            return <FaSortUp />;
          }

          return <FaSort />;
        };

        const updateSort = (dataField: string): void => {
          if (dataField === 'date') {
            dataField = values.dateType;
          }

          const { [dataField]: sort, ...restSort } = values.sort;

          if (!sort) {
            setFieldValue('sort', {
              ...values.sort,
              [dataField]: 'DESC',
            });
          } else if (sort === 'DESC') {
            setFieldValue('sort', {
              ...values.sort,
              [dataField]: 'ASC',
            });
          } else {
            setFieldValue('sort', restSort);
          }
        };

        const displayColumns = useMemo(
          () =>
            availableColumns.filter((aC) =>
              values.columns.find((c) => c === aC.dataField)
            ),
          [values.columns]
        );

        return (
          <>
            <Pagination
              items={fetchItems}
              itemsPerPage={values.size}
              watchList={[values.sort]}
              resetParams={[
                values.size,
                values.region,
                values.company,
                values.layerID,
                values.mpVendorCampaignName,
                values.vendorName,
                values.searchText,
                values.dateRange,
                values.leadResult,
                values.dateType,
                values.publisherID,
                refresh,
              ]}
              page={values.page}
              onPageChange={(page: number) => setFieldValue('page', page)}
            >
              <Card className="my-5 mx-3">
                <CardHeader>
                  <CardTitle
                    tag="h3"
                    className="d-flex justify-content-between align-items-center mb-0"
                  >
                    Leads
                    <div>
                      <UncontrolledButtonDropdown>
                        <DropdownToggle color="dark" caret>
                          Actions
                        </DropdownToggle>
                        <DropdownMenu right>
                          <DropdownItem
                            onClick={() => {
                              if (errors && errors.dateRange) {
                                create(
                                  'danger',
                                  'Please fix search range before exporting'
                                );
                              } else if (!noGeneratingReports) {
                                create(
                                  'danger',
                                  'You can only generate one report at a time.',
                                  3000
                                );
                              } else {
                                exportItems(values);
                              }
                            }}
                          >
                            Export
                          </DropdownItem>
                          {flags?.allowLeadUploads && (
                            <DropdownItem onClick={() => toggleUploadModal()}>
                              Upload
                            </DropdownItem>
                          )}
                        </DropdownMenu>
                      </UncontrolledButtonDropdown>
                    </div>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <div className="mb-5">
                    <span style={{ float: 'right' }}>
                      <Button
                        size="md"
                        color="primary"
                        onClick={async () => {
                          setRefresh(true);
                          await client.reFetchObservableQueries();
                          setTimeout(() => setRefresh(false), 500);
                        }}
                      >
                        <div className="d-flex align-items-center">
                          Refresh &nbsp;
                          <FaSync
                            className={`${refresh ? 'loading-sync' : ''}`}
                          />
                        </div>
                      </Button>
                    </span>
                  </div>
                  <SearchControls
                    openTableOptionsModal={() =>
                      setTableOptionsModal((o) => !o)
                    }
                    advancedSearch={
                      <Row style={{ maxWidth: 580 }}>
                        <Col xs={12}>
                          <DateRangeField
                            className="leads-date-range"
                            name="dateRange"
                            id="dateRange"
                            label="Date Range"
                            customArrowIcon="-"
                            calendarIcon={
                              <div>
                                <FaCalendarMinus />
                              </div>
                            }
                            datepickerProps={{
                              renderMonthElement: undefined,
                            }}
                            ranges
                          />
                        </Col>
                        <Col xs={6}>
                          <SelectField
                            name="region"
                            label="Region"
                            isClearable
                            labelKey="key"
                            valueKey="key"
                            options={
                              leadAggData?.leadAggregation.find(
                                (f) => f.field === 'region.keyword'
                              ).counts || []
                            }
                          />
                        </Col>

                        <Col xs={6}>
                          <SelectField
                            name="company"
                            label="Company"
                            isClearable
                            labelKey="key"
                            valueKey="key"
                            options={
                              leadAggData?.leadAggregation.find(
                                (f) => f.field === 'company.keyword'
                              ).counts || []
                            }
                          />
                        </Col>

                        <Col xs={6}>
                          <SelectField
                            name="layerID"
                            label="Layer ID"
                            isClearable
                            labelKey="key"
                            valueKey="key"
                            options={
                              leadAggData?.leadAggregation.find(
                                (f) => f.field === 'layerID.keyword'
                              ).counts || []
                            }
                          />
                        </Col>
                        <Col xs={6}>
                          <SelectField
                            name="mpVendorCampaignName"
                            label="Lead Source"
                            isClearable
                            getOptionLabel={(opt) =>
                              formatVendorCampaign(opt.key)
                            }
                            valueKey="key"
                            options={
                              leadAggData?.leadAggregation.find(
                                (f) =>
                                  f.field === 'mpVendorCampaignName.keyword'
                              ).counts || []
                            }
                          />
                        </Col>
                        {vendorID &&
                          displayColumns.find(
                            (dC: any) => dC.dataField === 'publisherName'
                          ) && (
                            <Col xs={6}>
                              <SelectField
                                name="publisherID"
                                label="Publisher"
                                isClearable
                                labelKey="publisherID"
                                valueKey="publisherID"
                                options={pubMany}
                                getOptionLabel={(opt: any) => {
                                  return opt.publisherName;
                                }}
                              />
                            </Col>
                          )}
                        {flags?.allowLeadResults && (
                          <Col xs={6}>
                            <SelectField
                              name="leadResult"
                              label="Lead Result"
                              isClearable
                              options={leadResultOptions}
                            />
                          </Col>
                        )}
                      </Row>
                    }
                  />
                  <SearchSummary className="d-flex justify-content-between py-3" />
                  <Table striped size="sm" hover>
                    <thead>
                      <tr>
                        {/* <th aria-label="break-label" /> */}
                        {displayColumns.map((column: any) => {
                          const showCheck = [
                            'emailOnly',
                            'isDuplicate',
                            'passDNC',
                            'passQuality',
                          ].includes(column.dataField);
                          return (
                            <th key={column.displayName}>
                              <div
                                className="d-flex align-items-center cursor-pointer"
                                role="button"
                                tabIndex={0}
                                onClick={() => updateSort(column.dataField)}
                                onKeyDown={(e: React.KeyboardEvent) =>
                                  e.charCode === 13 &&
                                  updateSort(column.dataField)
                                }
                              >
                                {column.displayName}{' '}
                                {showCheck && (
                                  <FaCheckCircle className=" mx-1 text-success" />
                                )}
                                {getSortIcon(column.dataField)}
                              </div>
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    <PaginationContent
                      itemKey="leadID"
                      component={TableRow}
                      containerTag="tbody"
                      filters={values}
                      columns={displayColumns}
                    />
                  </Table>
                  {/* )} */}
                  <NoItemsMessage className="d-flex justify-content-center align-items-center pt-2 pb-2">
                    There are no items left to view
                  </NoItemsMessage>
                </CardBody>
              </Card>
            </Pagination>

            <LeadsShowExportModal
              isOpen={showExportModal}
              toggle={() => setShowExportModal((o) => !o)}
            />
            <LeadsUploadModal isOpen={uploadModal} toggle={toggleUploadModal} />
            <TableOptionsModal
              availableColumns={availableColumns}
              isOpen={showTableOptionsModal}
              toggle={() => setTableOptionsModal((o) => !o)}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default Leads;
