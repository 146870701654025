/* eslint-disable consistent-return */
/* eslint-disable no-useless-computed-key */
/* eslint-disable unicorn/consistent-function-scoping */
import { useEffect, useState } from 'react';
import aws4 from 'aws4';
import { WebSocketLink } from 'apollo-link-ws';
import { ICredentials } from '@aws-amplify/core';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import url from 'url';
import { Vars } from '@/utils';
import { split, HttpLink, ApolloLink } from 'apollo-boost';
import { getMainDefinition } from 'apollo-utilities';
import { SubscriptionClient } from 'subscriptions-transport-ws';

const ONE_HOUR_IN_MS = 1000 * 60 * 55;

const createHttpLink = ({
  secretAccessKey,
  accessKeyId,
  sessionToken,
  agentID,
}: any): HttpLink => {
  const awsGraphqlFetch = (uri: string, options: any): Promise<Response> => {
    options = options || {};
    const signable: any = {};
    const urlObject = url.parse(uri);
    signable.host = urlObject.host;
    signable.path = urlObject.path;
    ['method', 'body', 'headers', 'region', 'service'].forEach(
      (key: string) => {
        signable[key] = options[key];
      }
    );

    aws4.sign(signable, {
      secretAccessKey,
      accessKeyId,
      sessionToken,
    });
    options.headers = signable.headers;

    if (uri === '/graphql') {
      options.headers['x-identity-header'] = agentID;
    }
    options.headers[
      'x-time-zone'
    ] = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return fetch(uri, options);
  };

  return new HttpLink({
    uri: Vars.uri,
    fetch: awsGraphqlFetch,
  });
};

const createWebSocketLink = (
  subscriptionClient: SubscriptionClient
): WebSocketLink => {
  return new WebSocketLink(subscriptionClient);
};

const useApolloLink = (authState?: string): ApolloLink | undefined => {
  const [link, setLink] = useState<ApolloLink | undefined>(undefined);
  const [currentCredentials, setCurrentCredentials] = useState<
    ICredentials | undefined
  >(undefined);
  const [currentSession, setCurrentSession] = useState<
    CognitoUserSession | undefined
  >(undefined);

  useEffect(() => {
    if (!['signedIn', 'verifyContact'].includes(authState || '')) {
      setLink(undefined);
      return;
    }

    const fetchInfo = async () => {
      const creds = await Auth.currentCredentials();
      const session = await Auth.currentSession();

      setCurrentCredentials(creds);
      setCurrentSession(session);
    };

    fetchInfo();
  }, [authState]);

  useEffect(() => {
    let tmId: any;
    if (currentSession && currentCredentials) {
      const refreshTm =
        (currentSession.getAccessToken().getExpiration() -
          currentSession.getAccessToken().getIssuedAt()) *
          1000 -
        5000;
      tmId = setTimeout(async () => {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        cognitoUser.refreshSession(
          currentSession.getRefreshToken(),
          (_: any, session: any) => {
            setCurrentSession(session);
          }
        );
      }, refreshTm || ONE_HOUR_IN_MS);
    }

    return () => {
      if (tmId) {
        clearTimeout(tmId);
      }
    };
  }, [currentSession, currentCredentials]);

  useEffect(() => {
    if (
      !['signedIn', 'verifyContact'].includes(authState || '') ||
      !currentCredentials ||
      !currentSession
    ) {
      setLink(undefined);
      return;
    }

    let subscriptionClient: SubscriptionClient;

    const createLink = async (): Promise<any> => {
      const {
        secretAccessKey,
        accessKeyId,
        sessionToken,
        identityId: agentID,
      } = currentCredentials;

      subscriptionClient = new SubscriptionClient(
        Vars.subScriptionUri as string,
        {
          connectionParams: {
            accessToken: currentSession.getAccessToken().getJwtToken(),
            refeshToken: currentSession.getRefreshToken().getToken(),
            agentID,
            networkID: currentSession.getIdToken().decodePayload()[
              'custom:networkID'
            ],
            vendorID: currentSession.getIdToken().decodePayload()[
              'custom:vendorID'
            ],
            publisherID: currentSession.getIdToken().decodePayload()[
              'custom:publisherID'
            ],
            ClientId: Vars.awsmobile.aws_user_pools_web_client_id,
          },
          reconnect: true,
        }
      );

      const wsLink = createWebSocketLink(subscriptionClient);

      const httpLink = createHttpLink({
        sessionToken,
        accessKeyId,
        secretAccessKey,
        agentID,
        accessToken: currentSession.getAccessToken().getJwtToken(),
      });

      setLink(
        split(
          ({ query }) => {
            const { kind, operation } = getMainDefinition(query);
            return (
              kind === 'OperationDefinition' && operation === 'subscription'
            );
          },
          wsLink,
          httpLink
        )
      );
    };

    createLink();

    return () => {
      if (subscriptionClient) {
        subscriptionClient.close();
      }
    };
  }, [currentCredentials, currentSession, authState]);

  return link;
};

export default useApolloLink;
