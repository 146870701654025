export { default as Vars } from './env-var';

export const currencyFormater = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const formatCurrencyForDisplay = (
  centsInteger: number,
  showCents = true
): string =>
  `$${(centsInteger / 100)
    .toFixed(showCents ? 2 : 0)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;

export const MS_PER_DAY = 86400000;

export const MS_PER_YEAR = MS_PER_DAY * 365;

export const MS_PER_99_YEARS = MS_PER_YEAR * 99;

export const formatVendorCampaign = (s: string): string =>
  s
    ?.split('_')
    .splice(
      s.startsWith('next') ||
        (s.startsWith('quote') &&
          !s.startsWith('quoteStorm') &&
          !s.startsWith('quotewizard'))
        ? 2
        : 1
    )
    .join(' ');
export const leadResultOptions = [
  { label: 'Black Listed', value: 'passDNC' },
  { label: 'Disconnected', value: 'emailOnly' },
  { label: 'Duplicate', value: 'isDuplicate' },
  { label: 'Failed Quality', value: 'passQuality' },
];
