import React, { useState, useEffect, useContext } from 'react';
import { Nav, NavItem, Badge } from 'reactstrap';
import classnames from 'classnames';
import { FaQuestion, FaFileCsv, FaPersonBooth } from 'react-icons/fa';
import { NotificationIcon } from '@/components';
import { useLocation, useHistory } from 'react-router-dom';
import { Vars } from '@/utils';
import { NotificationContext } from '@/context';
import { useAdmin } from '@/hooks';

interface LeftNavProps {
  collapsed: boolean;
}

type NavItemType = {
  label: string;
  icon: any;
  path: string;
  disabled?: boolean;
  roles?: string[];
};
const navItems: NavItemType[] = [
  {
    label: 'Leads',
    icon: <FaPersonBooth />,
    path: '/leads',
  },
  {
    label: 'Reports',
    icon: <FaFileCsv />,
    path: '/reports',
  },
];

const LeftNav: React.SFC<LeftNavProps> = ({ collapsed }) => {
  const [minWidth, setMinWidth] = useState<number | undefined>(
    collapsed ? 76 : 260
  );
  const paddingClassName = collapsed ? 'px-3' : 'px-5';
  const location = useLocation();
  const history = useHistory();

  const { tabNotification, setTabNotification } = useContext(
    NotificationContext
  );

  const { role, publisherID } = useAdmin();

  const isPublisher = !!publisherID;

  const { siteTitle, collapsedSiteTitle } = window.location.href.includes(
    'ushamarketplace'
  )
    ? {
        siteTitle: 'USHA',
        collapsedSiteTitle: 'UH',
      }
    : {
        siteTitle: 'Lead Reports',
        collapsedSiteTitle: isPublisher ? 'PB' : 'VN',
      };
  useEffect(() => {
    setTabNotification({
      ...tabNotification,
      reports: !(location.pathname === '/reports') && tabNotification.reports,
    });
  }, [tabNotification.reports]);

  useEffect(() => {
    setMinWidth(undefined);
    const tm = setTimeout(() => {
      setMinWidth(collapsed ? 76 : 260);
    }, 300);

    return () => clearTimeout(tm);
  }, [collapsed]);

  return (
    <Nav
      navbar
      vertical
      style={{
        backgroundColor: '#393939',
        width: collapsed ? 76 : 260,
        minWidth,
        borderRight: '2px solid #4a4a4a',
      }}
      className={classnames('pt-3 navbar-transition')}
    >
      <NavItem
        className={`h3 d-flex flex-column cursor-pointer text-center mb-4 ${paddingClassName}`}
        onClick={() => history.push('/leads')}
      >
        {collapsed ? (
          <span>
            {collapsedSiteTitle}
            {Vars.env === 'dev' && <div className="round-test-icon" />}
          </span>
        ) : (
          <div className="admin-header">
            <span className="d-flex align-items-center">
              {isPublisher ? 'Publisher' : 'Vendor'}
              {Vars.env === 'dev' && (
                <Badge
                  style={{
                    fontSize: '.9rem',
                  }}
                  color="warning"
                  title="This is the marketplace test version."
                  className="ml-2"
                >
                  Test
                </Badge>
              )}
            </span>
          </div>
        )}
      </NavItem>
      {navItems.map((item) => {
        const active =
          item.path !== '/'
            ? location.pathname.includes(item.path)
            : location.pathname === '/';

        return (
          <NavItem
            key={item.path}
            className={classnames(
              'py-3 d-flex align-items-center admin-nav-item cursor-pointer mb-0',
              {
                'px-3 h5': !collapsed,
                'px-1 justify-content-center h3': collapsed,
                'bg-dark admin-nav-item-active': active,
                'text-muted': item.disabled,
              }
            )}
            onClick={() => {
              setTabNotification({
                ...tabNotification,
                [item.label.toLowerCase()]: false,
              });
              return !item.disabled && history.push(item.path);
            }}
          >
            <div className="d-flex align-items-center">
              {item.icon}
              {!collapsed && <span className="ml-2">{item.label}</span>}
              {tabNotification[item.label.toLowerCase()] && !active && (
                <NotificationIcon color="danger" />
              )}
            </div>
          </NavItem>
        );
      })}
    </Nav>
  );
};

export default LeftNav;
