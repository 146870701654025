import React from 'react';
import { Container, Alert } from 'reactstrap';
import {
  Authenticator,
  Greetings,
  SignIn as OGSignIn,
  SignUp as OGSignUp,
  ConfirmSignUp as OGConfirmSignUp,
  VerifyContact as OGVerifyContact,
  ForgotPassword as OGForgotPassword,
  RequireNewPassword as OGRequireNewPassword,
} from 'aws-amplify-react';
import {
  SignIn,
  ConfirmSignUp,
  ForgotPassword,
  RequireNewPassword,
} from '@/components/Auth';

const AmplifyAuthenticator: React.SFC = ({ children }) => {
  return (
    <Authenticator
      errorMessage={(message) => (
        <Container className="px-0 mt-5" style={{ maxWidth: 500 }}>
          <Alert color="danger">{message}</Alert>
        </Container>
      )}
      hide={[
        Greetings,
        OGSignIn,
        OGSignUp,
        OGVerifyContact,
        OGConfirmSignUp,
        OGForgotPassword,
        OGRequireNewPassword,
      ]}
    >
      <SignIn />
      <ForgotPassword />
      <ConfirmSignUp />
      <RequireNewPassword />
      {children}
    </Authenticator>
  );
};

export default AmplifyAuthenticator;
