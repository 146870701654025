import gql from 'graphql-tag';

export default gql`
  query publisherManyQuery($publisherIDs: [String]) {
    publisherMany(publisherIDs: $publisherIDs) {
      id
      __typename
      publisherID
      publisherName
    }
  }
`;
