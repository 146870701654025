import gql from 'graphql-tag';

export default gql`
  query paginationQuery(
    $perPage: Int
    $page: Int
    $filter: LeadCollectionFilters
    $orderBy: LeadOrderInput
  ) {
    leadPagination(
      perPage: $perPage
      page: $page
      filter: $filter
      orderBy: $orderBy
    ) {
      count
      elasticQuery
      items {
        leadID
        vendorLeadID
        mpVendorCampaignName
        crmLastResultcode
        company
        layerID
        created
        email
        income
        household
        conditions
        publisherName
        tierID
        emailOnly
        postalCode
        soldDate
        phone
        vendorPrice
        region
        isDuplicate
        passDNC
        passQuality
        trustedFormCertID
        jornayaLeadID
        trustedFormLink
      }
      pageInfo {
        pageCount
        currentPage
      }
    }
  }
`;
