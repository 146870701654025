import React from 'react';
import moment from 'moment';
import { Badge } from 'reactstrap';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import { formatCurrencyForDisplay, formatVendorCampaign } from '.';

export const sizeOptions = [
  {
    label: '2',
    value: 2,
  },
  {
    label: '10',
    value: 10,
  },
  {
    label: '25',
    value: 25,
  },
  {
    label: '50',
    value: 50,
  },
  {
    label: '100',
    value: 100,
  },
];

export const publisherColumns = [
  {
    dataField: 'date',
    displayName: 'Date',
    default: true,
    formatter: (value: string): string =>
      moment(value).format('MM/DD/YY hh:mm A'),
  },
  {
    dataField: 'leadID',
    displayName: 'ID',
  },
  {
    dataField: 'phone',
    displayName: 'Phone',
    default: true,
  },
  {
    dataField: 'region',
    displayName: 'Region',
    default: true,
  },
  {
    dataField: 'postalCode',
    displayName: 'Zip Code',
    default: true,
  },
  {
    dataField: 'household',
    displayName: 'Household',
    default: true,
  },
  {
    dataField: 'conditions',
    displayName: 'Conditions',
    default: true,
  },
  {
    dataField: 'income',
    displayName: 'Income',
    default: true,
  },
  {
    dataField: 'emailOnly',
    displayName: 'Disconnected',
    default: true,
    formatter: (value) => (
      <Badge color={!value ? 'success' : 'danger'}>
        {!value ? <FaCheckCircle /> : <FaTimesCircle />}
      </Badge>
    ),
  },
  {
    dataField: 'isDuplicate',
    displayName: 'Duplicate',
    default: true,
    formatter: (value) => (
      <Badge color={!value ? 'success' : 'danger'}>
        {!value ? <FaCheckCircle /> : <FaTimesCircle />}
      </Badge>
    ),
  },
  {
    dataField: 'passDNC',
    displayName: 'Black List',
    default: true,
    formatter: (value) => (
      <Badge color={value ? 'success' : 'danger'}>
        {value ? <FaCheckCircle /> : <FaTimesCircle />}
      </Badge>
    ),
  },
  {
    dataField: 'passQuality',
    displayName: 'Pass Quality',
    default: true,
    formatter: (value) => (
      <Badge color={value || value === null ? 'success' : 'danger'}>
        {value || value === null ? <FaCheckCircle /> : <FaTimesCircle />}
      </Badge>
    ),
  },
];

const getErrorText = (
  passQuality?: boolean,
  passDNC?: boolean,
  isDuplicate?: boolean
): string => {
  if (!passQuality) {
    return 'Failed Quality';
  }

  if (!passDNC) {
    return 'Black Listed';
  }

  if (isDuplicate) {
    return 'Duplicate';
  }

  return 'Disconnected';
};

export const vendorColumns = [
  {
    dataField: 'publisherName',
    displayName: 'Publisher',
  },
  {
    dataField: 'company',
    displayName: 'Company',
  },
  {
    dataField: 'vendorLeadID',
    displayName: 'Vendor Lead ID',
  },
  {
    dataField: 'layerID',
    displayName: 'Layer ID',
  },
  {
    dataField: 'mpVendorCampaignName',
    displayName: 'Lead Source',
    formatter: formatVendorCampaign,
  },
  {
    dataField: 'date',
    displayName: 'Date',
    default: true,
    formatter: (value: string): string =>
      moment(value).format('MM/DD/YY hh:mm A'),
  },
  {
    dataField: 'leadID',
    displayName: 'ID',
  },

  {
    dataField: 'phone',
    displayName: 'Phone',
    default: true,
  },
  {
    dataField: 'email',
    displayName: 'Email',
    default: true,
  },
  {
    dataField: 'region',
    displayName: 'Region',
    default: true,
  },
  {
    dataField: 'vendorPrice',
    displayName: 'Price',
    default: true,
    formatter: (value: number) => formatCurrencyForDisplay(value * 100),
  },
  {
    dataField: 'custom-result',
    displayName: 'Lead Result',
    default: true,
    formatter: ({ passQuality, passDNC, isDuplicate, emailOnly }: any) => {
      if (!passQuality || !passDNC || isDuplicate || emailOnly) {
        return (
          <Badge color="danger" className="d-flex">
            <span>{getErrorText(passQuality, passDNC, isDuplicate)}</span>{' '}
            <FaTimesCircle className="ml-1" />
          </Badge>
        );
      }

      return (
        <Badge color="success" className="d-flex">
          <span>Accepted</span> <FaCheckCircle className="ml-1" />
        </Badge>
      );
    },
  },
  {
    dataField: 'crmLastResultcode',
    displayName: 'CRM Result',
    default: true,
  },
  {
    dataField: 'jornayaLeadID',
    displayName: 'Jornaya Lead ID',
    default: false,
  },
  {
    dataField: 'trustedFormCertID',
    displayName: 'Trusted Form Cert ID',
    default: false,
  },
  {
    dataField: 'trustedFormLink',
    displayName: 'Trusted Form Link',
    default: false,
  },
];
