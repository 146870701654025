import React, { useState } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  ModalProps,
  CustomInput,
} from 'reactstrap';
import LoadingButton from '@gopro16/button';
import moment from 'moment';
import { Storage } from 'aws-amplify';
import { Vars } from '@/utils';
import { useAdmin, useNotifications } from '@/hooks';

interface LeadModalProps extends Omit<ModalProps, 'toggle'> {
  toggle: () => void;
}

const LeadsUploadModal: React.FunctionComponent<LeadModalProps> = ({
  toggle,
  isOpen,
  ...rest
}) => {
  const currentUser = useAdmin();
  const { create } = useNotifications();
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
  const [uploading, setUploading] = useState(false);

  const uploadFile = async () => {
    setUploading(true);
    const filePath = `${currentUser.publisherID ? 'publisher' : 'vendor'}/${
      currentUser.publisherID || currentUser.vendorID
    }/${moment().format()}-${selectedFile!.name}`;

    console.log('selected file', selectedFile);

    if (selectedFile!.size > 310000) {
      create('danger', 'The maximum file size is 300 KB.');
      setUploading(false);
      return;
    }

    await Storage.vault.put(filePath, selectedFile, {
      level: 'public',
      contentType: selectedFile?.type,
      bucket: `lead-uploads-${Vars.env}`,
      region: 'us-east-1',
    });

    create('success', 'Your leads are being processed.');

    setUploading(false);
    toggle();
  };

  return (
    <Modal size="md" isOpen={isOpen} toggle={toggle} {...rest}>
      <ModalHeader toggle={toggle}>Upload Leads</ModalHeader>
      <ModalBody>
        <div className="mb-3">
          Accepted file types are limited to CSV. Once your file has finished
          being processed you will get a summary report.
        </div>
        <CustomInput
          name="fileUpload"
          type="file"
          multiple={false}
          accept=".csv"
          onChange={(e) => setSelectedFile(e.target.files?.[0])}
        />
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={toggle}>
          Cancel
        </Button>
        <LoadingButton
          loading={uploading}
          color="primary"
          onClick={uploadFile}
          disabled={!selectedFile || uploading}
        >
          Upload
        </LoadingButton>
      </ModalFooter>
    </Modal>
  );
};

export default LeadsUploadModal;
