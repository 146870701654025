import React, { useState, useRef, useEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { IAuthPieceProps } from 'aws-amplify-react/lib/Auth/AuthPiece';
import Amplify from 'aws-amplify';
import { ReportsPage, LeadsPage } from '@/areas';
import {
  NotificationProvider,
  LoadingBarProvider,
  CurrentUserProvider,
} from '@/context';
import AmplifyAuthenticator from '@/components/Auth/AmplifyAuthenticator';
import { LeftNav, TopNav } from '@/components/Nav';
import { Vars } from './utils';

Amplify.configure(Vars.awsmobile);

const AppWrapper: React.SFC = () => {
  const [collapsed, setCollapsed] = useState(
    localStorage.getItem('admin-navbar-collapsed') === 'true'
  );
  const ref = useRef<HTMLDivElement>(null);
  const location = useLocation();

  useEffect(() => {
    ref.current?.scrollTo(0, 0);
  }, [location]);

  return (
    <div className="h-100 d-flex">
      <LeftNav collapsed={collapsed} />
      <div
        ref={ref}
        className="sonic-container flex-fill d-flex flex-column"
        style={{ height: '100%', overflowY: 'scroll' }}
      >
        <TopNav
          toggleCollapse={() => {
            setCollapsed((o) => !o);
            localStorage.setItem(
              'admin-navbar-collapsed',
              collapsed ? 'false' : 'true'
            );
          }}
        />
        <LoadingBarProvider>
          <Switch>
            <Route exact path="/">
              <Redirect to="/leads" />
            </Route>
            <Route component={ReportsPage} exact path="/reports" />
            <Route component={LeadsPage} exact path="/leads" />
          </Switch>
        </LoadingBarProvider>
      </div>
    </div>
  );
};

const AuthorizedApp: React.SFC<IAuthPieceProps> = ({ authState }) => {
  return authState === 'signedIn' || authState === 'verifyContact' ? (
    <CurrentUserProvider>
      <NotificationProvider>
        <AppWrapper />
      </NotificationProvider>
    </CurrentUserProvider>
  ) : null;
};

const App: React.SFC = () => (
  <AmplifyAuthenticator>
    <AuthorizedApp />
  </AmplifyAuthenticator>
);

export default App;
