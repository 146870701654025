import React, { createContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { currentAdminQuery } from '@/graphql/queries';

export interface NotificationContextType {
  name: string;
  email: string;
  role: 'publisher';
  agentID: string;
  publisherID: string;
  vendorID: string;
  flags?: {
    allowLeadUploads: boolean;
    allowLeadResults: boolean;
  };
}
export const CurrentUserContext = createContext<NotificationContextType>({
  name: '',
  email: '',
  role: 'publisher',
  agentID: '',
  publisherID: '',
  vendorID: '',
});

const CurrentUserProvider: React.SFC = ({ children }) => {
  const { data, loading } = useQuery(currentAdminQuery);

  if (loading) return null;

  return (
    <CurrentUserContext.Provider value={data?.currentAdmin || {}}>
      {children}
    </CurrentUserContext.Provider>
  );
};

export default CurrentUserProvider;
