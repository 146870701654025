/* eslint-disable import/prefer-default-export */
export const reportOptions = [
  {
    label: 'Rejects Report',
    reportType: 'vendorRefunds',
  },
  {
    label: 'All Leads Report',
    reportType: 'allLeads',
  },
  {
    label: 'Advercates Report',
    reportType: 'advercates',
  },
];
