import React, { useEffect, useState, useContext } from 'react';
import { Auth } from 'aws-amplify';
import {
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap';
import classnames from 'classnames';
import { FaBars, FaCaretDown } from 'react-icons/fa';
import { useApolloClient, useQuery, useLazyQuery } from '@apollo/react-hooks';
import { useNotifications } from '@/hooks';
import { CurrentUserContext } from '@/context';
import { Form, Input } from '@availity/form';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

interface User {
  name: string;
  email: string;
  agentID: string;
  role: 'admin' | 'internal_admin' | 'super_internal_admin';
}

interface TopNavProps {
  toggleCollapse: () => void;
}

const TopNav: React.SFC<TopNavProps> = ({ toggleCollapse }) => {
  const client = useApolloClient();
  const { create: createNotification } = useNotifications();
  const history = useHistory();
  const { role, email, name } = useContext(CurrentUserContext);

  return (
    <div
      className="border-bottom border-secondary py-3 px-3 d-flex justify-content-between align-items-center"
      style={{
        height: 77,
      }}
    >
      <Button color="primary" size="sm" onClick={toggleCollapse}>
        <FaBars size={20} />
      </Button>
      <UncontrolledDropdown inNavbar>
        <DropdownToggle tag="div" className="d-flex cursor-pointer">
          <div className="d-flex flex-column align-items-end text-light font-weight-bold">
            <span>{email}</span>
            <span>Hello, {name}</span>
          </div>
          <FaCaretDown className="ml-2 mt-1" />
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem
            onClick={() => {
              Auth.signOut();
              window.localStorage.removeItem('admin-network-id');
              client.cache.reset();
            }}
          >
            Logout
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
};

export default TopNav;
