import gql from 'graphql-tag';
import { VendorFragment } from '../fragments';

export default gql`
  query vendorManyQuery {
    vendorMany {
      ...VendorFragment
    }
  }
  ${VendorFragment}
`;
