/* eslint-disable import/no-cycle */
/* eslint-disable no-useless-computed-key */
/* eslint-disable no-empty */
/* eslint-disable no-case-declarations */
import React, {
  createContext,
  useState,
  useCallback,
  useEffect,
  useContext,
  useRef,
} from 'react';
import { uuid } from 'uuidv4';
import { Alert } from 'reactstrap';
import { reportManyQuery } from '@/graphql/queries';
import { ReportFragment } from '@/graphql/fragments';
import { reportNotificationSubscription } from '@/graphql/subscriptions';
import { useApolloClient, useSubscription } from '@apollo/react-hooks';
import { CurrentUserContext } from './CurrentUserContext';

export interface Notification {
  color: string;
  dismiss: () => void;
  id: string;
  message: string;
}

type ReportData = {
  url: string;
  fileName: string;
  type: string;
  id: string;
  message: string;
  cleanName: string;
  creationDate: number;
  status: string;
};

export interface TabNotification {
  reports: boolean;
}

export interface NotificationContextType {
  create: (color: string, message: string, timeout?: number) => void;
  notifications: Notification[];
  tabNotification: TabNotification;
  setTabNotification: (obj: TabNotification) => void;
}

export const NotificationContext = createContext<NotificationContextType>({
  create: () => {},
  notifications: [],
  tabNotification: {
    reports: false,
  },
  setTabNotification: () => {},
});

const NotificationProvider: React.SFC = ({ children }) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [tabNotification, setTabNotification] = useState<TabNotification>({
    reports: false,
  });

  const { publisherID, vendorID } = useContext(CurrentUserContext);
  const client = useApolloClient();
  const create = useCallback(
    (color: string, message: string, timeout = 5000): void => {
      if (color === 'error') {
        color = 'danger';
      }
      const id = uuid();

      const dismiss = (): void =>
        setNotifications((currentNotifications) =>
          currentNotifications.filter((n) => n.id !== id)
        );

      setNotifications(
        notifications.concat([
          {
            color,
            dismiss,
            id,
            message,
          },
        ])
      );
      setTimeout(() => {
        dismiss();
      }, timeout);
    },
    [notifications, setNotifications]
  );

  const onSubscriptionData = ({
    subscriptionData: { data: subdata },
  }: any): any => {
    const { notification = {} } = subdata || {};

    const { data = { message: {} } } = notification as {
      notificationID: string;
      data: any;
      timeout: number;
      action: string;
    };

    if (data.message.requestType === 'REPORT') {
      const {
        status,
        fileName,
        url,
        id,
        creationDate,
        cleanName,
        message,
        type,
      } = data.message as ReportData;

      switch (type) {
        case 'allLeads':
        case 'homeOfficeCPA':
        case 'vendorRefunds':
        case 'lead':
        case 'advercates':
          if (status === 'GENERATING') {
            try {
              const { reportMany: _reports } =
                client.cache.readQuery({
                  query: reportManyQuery,
                  variables: {
                    folderID: publisherID || vendorID,
                    reportFolder: publisherID ? 'publisher' : 'vendor',
                  },
                }) || ({} as any);

              if (_reports) {
                client.cache.writeQuery({
                  query: reportManyQuery,
                  variables: {
                    folderID: publisherID || vendorID,
                    reportFolder: publisherID ? 'publisher' : 'vendor',
                  },
                  data: {
                    reportMany: [
                      {
                        status,
                        fileName,
                        url,
                        id,
                        creationDate,
                        cleanName,
                        ['__typename']: 'Report',
                      },
                      ..._reports,
                    ],
                  },
                });
              }
            } catch {
              client.cache.writeQuery({
                query: reportManyQuery,
                variables: {
                  folderID: publisherID || vendorID,
                  reportFolder: publisherID ? 'publisher' : 'vendor',
                },
                data: {
                  reportMany: [
                    {
                      status,
                      fileName,
                      url,
                      id,
                      creationDate,
                      cleanName,
                      ['__typename']: 'Report',
                    },
                  ],
                },
              });
            }
            data.message = `Currently generating report.`;
            data.color = 'success';
            break;
          }
          data.message = message;
          data.color = 'success';

          setTabNotification({
            ...tabNotification,
            reports: true,
          });
          break;

        case 'failure':
          data.message = 'Failed to generate report';
          data.color = 'danger';
          break;
        default:
          console.log(`${type} type hasn't been set up for notifications yet.`);
          break;
      }

      try {
        const exists = client.cache.readFragment({
          id,
          fragment: ReportFragment,
        });

        if (exists) {
          client.cache.writeData({
            id,
            data: {
              status,
            },
          });
        }
      } catch {
        console.log('Failed to update cache.');
      }
      client.queryManager.broadcastQueries();
    }

    if (data) {
      create(data.color, data.message, 3000);
    }
  };

  useSubscription<{
    notification: Notification;
  }>(reportNotificationSubscription, {
    onSubscriptionData,
    variables: {
      vendorID,
      publisherID,
    },
  });

  return (
    <NotificationContext.Provider
      value={{ create, notifications, tabNotification, setTabNotification }}
    >
      <div
        style={{
          position: 'fixed',
          top: 20,
          right: 16,
          zIndex: 1051, // Any lower (even 1050) and the notifications will be hidden under modals
        }}
      >
        {notifications.map((n) => (
          <Alert color={n.color} key={n.id} toggle={n.dismiss}>
            {n.message}
          </Alert>
        ))}
      </div>
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
